import { StyleSheet } from 'react-native';
import { BuilderTheme } from '../ThemeProvider/themes';
import COLORS from '../../utils/colors';

const hs = (value: number) => value;
const ms = (value: number) => value;

export const SIZE = 60;

export const createStyles = (theme: BuilderTheme | undefined) =>
    StyleSheet.create({
        container: {
            height: hs(60),
            width: hs(60),
            borderRadius: 10000,
            backgroundColor: theme?.colors.secondaryBackground ?? 'transparent',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            borderWidth: theme?.spacing?.borderWidth ?? 2,
            borderColor: theme?.colors.border ?? '#F0E5FF',
        },
        overlay: {
            position: 'absolute',
            backgroundColor: theme?.colors.shadow ?? '#000',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: theme?.spacing?.borderWidth ?? 2,
        },
        image: {
            height: '100%',
            width: '100%',
        },
        text: {
            fontSize: theme?.typography?.p?.fontSize ?? ms(24),
            color: theme?.colors.primary ?? COLORS.COLOR_PRIMARY_PURPLE,
            fontWeight: theme?.typography?.p?.fontWeight,
        },
        icon: {
            fontSize: ms(18),
        },
    });
