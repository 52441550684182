import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const createStyles = (theme) => {
    return StyleSheet.create({
        text: {
            fontSize: theme?.typography?.p?.fontSize ?? 10,
            fontWeight: theme?.typography?.p?.fontWeight ?? '500',
            color: theme?.colors?.text ?? COLORS.GREY_TEXT,
        },
        selectedText: {
            color: theme?.colors?.primary ?? COLORS.COLOR_MEDIUMBLUE,
        },
        tab: {
            alignItems: 'center',
            justifyContent: 'center',
            color: theme?.colors?.text ?? COLORS.GREY_TEXT,
        },
        contentContainer: {
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
        },
        mainView: {
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        container: {
            width: '100%',
            display: 'flex',
            backgroundColor: theme?.colors?.background ?? COLORS.COLOR_WHITE,
        },
    })
}

export default createStyles
