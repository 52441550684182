import { StyleSheet } from 'react-native';
import { BuilderTheme } from '../ThemeProvider/themes';
import { horizontalScale, moderateScale } from '../../utils/screenRatio';
import COLORS from '../../utils/colors';

export const createBreadcrumbsStyles = (theme: BuilderTheme) =>
    StyleSheet.create({
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
    },
    hoverView: {
        padding: theme?.spacing?.padding ?? 8,
        borderRadius: theme?.spacing?.borderRadius ?? 7,
    },
    breadcrumbText: {
        color: theme?.colors?.text ?? COLORS.COLOR_NEUTRAL_GRAY,
        fontSize: theme?.typography?.p?.fontSize ?? moderateScale(10),
        fontWeight: theme?.typography?.p?.fontWeight ??'400',
    },
    boldText: {
        color: theme?.colors?.text ?? COLORS.COLOR_BLACK,
        fontSize: theme?.typography?.p?.fontSize ?? moderateScale(10),
        fontWeight: theme?.typography?.p?.fontWeight ?? '600',
    },
    icon: {
        width: 10,
        height: 10,
        tintColor: theme?.colors?.disabled ?? COLORS.COLOR_DISABLE_GREY,
        marginHorizontal: theme?.spacing?.margin ?? horizontalScale(2)
    },
    contentContainer: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    });
