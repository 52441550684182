import React, { useState } from 'react';
import { View, Image } from 'react-native';
import { Calendar as RNCalendar } from 'react-native-calendars';

import { useTheme } from '../ThemeProvider/ThemeProvider';
import { ICalendar, ICalendarState } from './interface';
import createStyles from './styles';
import COLORS from '../../utils/colors';

export const formatDate = (date: any) => {
    const selectedDate = new Date(date);
    const day = selectedDate.getDate();
    const month = selectedDate.toLocaleString('default', { month: 'long' });

    const formattedDate = `${day} ${month}`;
    return formattedDate;
};
/**
 * @deprecated Since version 2.0.0 Please use MultiCalendarView instead. Will be removed in next release
 */

const Calendar: React.FC<ICalendar> = (props) => {
    const { currentDate, markedDates = {} } = props;

    const timezoneOffset = new Date().getTimezoneOffset();
    const today = new Date(Date.now() - timezoneOffset);

    const current = (currentDate || today).toISOString().split('T')[0];

    const [state, setState] = useState<ICalendarState>({
        isCalendarVisible: false,
        markedDates: markedDates,
    });

    const { theme } = useTheme();
    const styles = createStyles(theme);

    const handleOnDayPress = (day: any) => {
        const { dateString } = day;
        const { markedDates } = state;

        const _markedDates = JSON.parse(JSON.stringify(markedDates));

        Object.values(_markedDates).forEach((val: any) => delete val.selected);

        const updateMarkedDates = {
            ..._markedDates,
            [dateString]: {
                ..._markedDates[dateString],
                selected: true,
            },
        };

        setState({
            ...state,
            markedDates: updateMarkedDates,
        });
    };

    const CalendarComponent = (props: ICalendar) => {
        const {
            markedDates,
            style,
            arrowRight,
            arrowLeft,
            theme: propTheme,
            onDayPress,
            ...rest
        } = props;

        return (
            <RNCalendar
                current={current}
                onDayPress={(e) => {
                    handleOnDayPress(e);
                    onDayPress && onDayPress(e);
                }}
                markedDates={markedDates ?? state.markedDates}
                style={[styles.calendarStyle, style]}
                renderArrow={(direction) => (
                    <View style={styles.renderArrowStyle}>
                        {direction === 'right'
                            ? arrowRight || (
                                <Image
                                    source={{ uri: 'https://i.imgur.com/zuh7pXB.png' }}
                                    style={styles.imgStyle}
                                />
                            )
                            : arrowLeft || (
                                <Image
                                    source={{ uri: 'https://i.imgur.com/5qQ3eEO.png' }}
                                    style={styles.imgStyle}
                                />
                            )}
                    </View>
                )}

                theme={{
                    calendarBackground: theme?.colors?.background ?? 'transparent',
                    selectedDayBackgroundColor: theme?.colors?.primary ?? COLORS.COLOR_BLUE,
                    selectedDayTextColor: theme?.colors?.text ?? COLORS.COLOR_HIGHLIGHTBLUE,
                    textDayFontWeight: theme?.typography?.p?.fontWeight ?? '400',
                    textDayFontSize: theme?.typography?.p?.fontSize ?? 14,
                    textSectionTitleColor: theme?.colors?.text ?? COLORS.COLOR_GRAY,
                    todayTextColor: theme?.colors?.text ?? COLORS.COLOR_BLACK,
                    dayTextColor: theme?.colors?.primary ?? COLORS.COLOR_LIGHTBLACK,
                    textDisabledColor: theme?.colors?.disabled ?? COLORS.COLOR_GRAY_PLACEHOLDER,
                    monthTextColor: theme?.colors?.primary ?? COLORS.COLOR_LIGHTBLACK,
                    textMonthFontWeight: theme?.typography?.p?.fontWeight ?? '700',
                    textMonthFontSize: theme?.typography?.p?.fontSize ?? 14,
                    ...propTheme,  // Apply any overrides from props
                }}
                {...rest}
            />
        );
    };

    return <CalendarComponent {...props} />;
};

export default Calendar;
