import { StyleSheet } from 'react-native'
import { BuilderTheme } from '../ThemeProvider/ThemeProvider'

export const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    itemContainer: {
        backgroundColor: theme?.colors?.background ?? '#fff',
        paddingHorizontal: theme?.spacing?.padding ?? 16,
        marginVertical: theme?.spacing?.margin ?? 8,
        borderRadius: theme?.spacing?.borderRadius ?? 8,
        overflow: 'hidden',
        justifyContent: 'space-between',
        width: '100%',
    },
    headerContainer: {
        width: '100%',
        justifyContent: 'space-between',
        paddingVertical: theme?.spacing?.padding ?? 2,
        marginBottom: theme?.spacing?.margin ?? 8,
    },
    titleWithIcon: {
        flexDirection: 'row',
        paddingRight: theme?.spacing?.padding ?? 8,
        justifyContent: 'space-between',
    },
    subTitle: {
        fontSize: theme?.typography?.p?.fontSize ?? 14,
        fontWeight: theme?.typography?.p?.fontWeight ?? '400',
        color: theme?.colors?.secondary ?? '#475569',
    },
    contentContainer: {
        backgroundColor: theme?.colors?.background ?? 'white',
        borderWidth: theme?.spacing?.borderWidth ?? 1,
        borderStyle: 'dashed',
        borderColor: theme?.colors?.border ?? '#9747FF',
        borderRadius: theme?.spacing?.borderRadius ?? 4,
        padding: theme?.spacing?.padding ?? 8,
        width: '100%',
    },
    iconStyle: {
        height: theme?.icons?.size ?? 24,
        width: theme?.icons?.size ?? 24,
        overflow: 'hidden',
        resizeMode: 'stretch',
    },
})
